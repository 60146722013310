import React, { useEffect } from 'react';
import Head from 'next/head';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import translateMsg from '~/src/lib/translateMsg';

/*  MARCHE PAS
j'utilise vercel.json qui redirige vers 404.html
*/
function App(props) {
    return (
        <React.Fragment>
            <Head>
                <title></title>
            </Head>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: 20,
                }}
            >
                <h3>{translateMsg('webapp', 'pwa_ui__404_Page_Not_Found')}</h3>

                <div
                    style={{
                        marginTop: 30,
                    }}
                >
                    <p>{translateMsg('webapp', 'pwa_ui__page_not_exist')}</p>
                    <p>
                        <Link href="/">
                            {translateMsg('webapp', 'pwa_ui__home')}
                        </Link>
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}

export default App;
